.memo-section {
    color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: auto;
}

.memo-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.title-input {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: 18px;
    width: 100%;
    margin-bottom: 10px;
    outline: none;
}

.content-area,
.markdown-preview {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.6;
    width: 100%;
    flex-grow: 1;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.content-area {
    resize: none;
    outline: none;
    min-height: 100px;
    overflow: hidden;
}

.content-area::placeholder {
    color: #666666;
}

/* Ensure the memo section takes up the full height of its container */
.overview-info {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.overview-info h3 {
    margin-bottom: 10px;
}

.formatting-toolbar {
    margin-bottom: 10px;
}

.formatting-toolbar button {
    margin-right: 5px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: pointer;
}

.formatting-toolbar button:hover {
    background-color: #e0e0e0;
}

.floating-toolbar {
    position: absolute;
    background-color: #1a1433;
    border: 1px solid #625896;
    border-radius: 5px;
    padding: 5px;
    z-index: 1000;
    transform: translateX(-50%);
}

.floating-toolbar button {
    background-color: transparent;
    border: none;
    color: #e0e0e0;
    margin: 0 5px;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 3px;
    transition: background-color 0.3s;
}

.floating-toolbar button:hover {
    background-color: #625896;
}

.markdown-preview {
    overflow-y: auto;
    cursor: text;
}

.content-area p,
.markdown-preview p {
    margin-bottom: 1em;
}

.placeholder {
    color: #667;
}