.new-message-composer {
    display: flex;
    flex-direction: column;
    background-color: #1e1e2e;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.composer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: #252836;
    border-bottom: 1px solid #2e303e;
    font-size: 1rem;

}

.composer-header h2 {
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    color: #6c7293;
    cursor: pointer;
    font-size: 1.2rem;
    transition: color 0.2s;
}

.close-button:hover {
    color: #ffffff;
}

.composer-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
}

.composer-field {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.composer-field label {
    color: #6c7293;
    font-size: 0.8rem;
    margin-right: 10px;
    width: 50px;
}

.composer-field input {
    background-color: #252836;
    border: 1px solid #2e303e;
    border-radius: 4px;
    color: #ffffff;
    font-size: 0.8rem;
    outline: none;
    padding: 6px 10px;
    width: 100%;
}

.composer-body {
    flex-grow: 1;
    display: flex;
    margin-bottom: 10px;
}

.new-message-composer {
    /* ... existing styles ... */
    position: relative;
    padding-top: 40px;
    /* Add space for the cancel button */
}

.cancel-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
    font-size: 1.2em;
    padding: 5px;
}

.cancel-button:hover {
    color: #555;
}

.composer-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.generate-button {
    /* ... existing styles ... */
    margin-right: auto;
    /* Push to the left */
}

.send-button {
    /* ... existing styles ... */
    margin-left: auto;
    /* Push to the right */
}

.composer-body textarea {
    background-color: #252836;
    border: 1px solid #2e303e;
    border-radius: 4px;
    color: #ffffff;
    flex-grow: 1;
    outline: none;
    padding: 10px;
    resize: none;
    font-size: 0.8rem;
    min-height: 200px;
    /* Add this line to ensure a minimum height */
}

.composer-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: #252836;
    border-top: 1px solid #2e303e;
}

.send-button {
    background-color: #3584e4;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    font-size: 0.8rem;
    padding: 6px 12px;
    font-weight: 600;
    padding: 8px 16px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    gap: 8px;
}

.send-button:hover {
    background-color: #2a6cb8;
}

::placeholder {
    color: #6c7293;
}