.chat-panel {
    position: fixed;
    right: 20px;
    top: 20px;
    bottom: 20px;
    width: 400px;
    background-color: var(--primary-bg);
    border: 1px solid var(--border-color);
    z-index: 1000;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.chat-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: var(--secondary-bg);
    border-bottom: 1px solid var(--border-color);
    border-radius: 10px 10px 0 0;
}

.chat-panel-header h3 {
    margin: 0;
    color: var(--text-color);
}

.chat-panel-header button {
    background-color: transparent;
    border: none;
    color: var(--text-color);
    cursor: pointer;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 3px;
    transition: background-color 0.3s;
}

.chat-panel-header button:hover {
    background-color: var(--accent-color);
}

.chat-history {
    flex-grow: 1;
    overflow-y: auto;
    padding: 15px;
    background-color: var(--primary-bg);
    scrollbar-width: thin;
    scrollbar-color: var(--accent-color) transparent;
}

.chat-history::-webkit-scrollbar {
    width: 8px;
}

.chat-history::-webkit-scrollbar-track {
    background: transparent;
}

.chat-history::-webkit-scrollbar-thumb {
    background-color: var(--accent-color);
    border-radius: 4px;
    border: 2px solid transparent;
    background-clip: padding-box;
}

.chat-history::-webkit-scrollbar-thumb:hover {
    background-color: #7a6eb8;
}

.message {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    background-color: var(--secondary-bg);
}

.message.highlight {
    border-left: 3px solid rgba(255, 217, 0, 0.3);
}

.message.user {
    border-left: 3px solid rgba(98, 88, 150, 0.6);
}

.message.assistant {
    border-left: 3px solid rgba(167, 40, 40, 0.6);
}

.chat-input-area {
    display: flex;
    padding: 15px;
    background-color: var(--secondary-bg);
    border-top: 1px solid var(--border-color);
    border-radius: 0 0 10px 10px;
}

.chat-input-area input {
    flex-grow: 1;
    padding: 10px 15px;
    margin-right: 10px;
    background-color: var(--primary-bg);
    border: 1px solid var(--border-color);
    border-radius: 20px;
    color: var(--text-color);
}

.chat-input-area button {
    padding: 10px 15px;
    background-color: var(--accent-color);
    border: none;
    border-radius: 20px;
    color: var(--text-color);
    cursor: pointer;
    transition: background-color 0.3s;
}

.chat-input-area button:hover {
    background-color: #7a6eb8;
}

/* Remove the highlights container styles as we'll integrate it into the chat history */
.highlights-container,
.highlight-box {
    display: none;
}