:root {
    --primary-bg: #080125;
    --secondary-bg: #1a1433;
    --accent-color: #625896;
    --text-color: white;
    --border-color: #625896;
}

body {
    background-color: var(--primary-bg);
    color: var(--text-color);
    font-family: Arial, sans-serif;
}

.app-container {
    background-color: var(--primary-bg);
}

.main-content {
    background-color: var(--secondary-bg);
}

input,
select,
textarea {
    background-color: var(--secondary-bg);
    color: var(--text-color);
    border: 1px solid var(--border-color);
}

button {
    background-color: var(--accent-color);
    color: var(--text-color);
    border: none;
}

button:hover {
    background-color: #7a6eb8;
}

table {
    background-color: var(--secondary-bg);
    color: var(--text-color);
}

th {
    background-color: var(--primary-bg);
}

tr:nth-child(even) {
    background-color: rgba(98, 88, 150, 0.1);
}

a {
    color: #a095d6;
}

a:hover {
    color: #c4bceb;
}

.sign-out-button {
    background-color: #f44336;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}

.sign-out-button:hover {
    background-color: #d32f2f;
}