.omnichannel-chat {
    display: flex;
    height: 500px;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

.chat-container {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.omni-left-panel {
    width: 200px;
    flex-shrink: 0;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #ccc;
}

.right-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 0;
}

.chat-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 0;
    width: 100%;
    /* Ensure the chat box takes full width */
}

.founder-info {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    font-size: 0.9em;
    font-weight: bold;
    background-color: #2c3e50;
    color: white;
    line-height: 1.2;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 768px) {
    .founder-info {
        font-size: 0.8em;
    }
}

.chat-messages {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.email-message-box {
    background-color: transparent;
    border: none;
    margin-bottom: 0;
}

.reply-box {
    margin-top: 10px;
    background-color: #1e2329;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reply-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 0.9em;
    color: #a0a0a0;
}

.reply-fields {
    margin-bottom: 10px;
}

.reply-field {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.reply-field label {
    width: 40px;
    font-size: 0.9em;
    color: #a0a0a0;
}

.reply-field input {
    flex: 1;
    padding: 5px;
    border: 1px solid #2c3035;
    border-radius: 4px;
    background-color: #13161a;
    color: #ffffff;
    font-size: 0.9em;
}

.reply-box textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #2c3035;
    border-radius: 4px;
    background-color: #13161a;
    color: #ffffff;
    font-size: 0.9em;
    resize: vertical;
}

.reply-actions {
    display: flex;
    justify-content: flex-end;
}

.reply-actions button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #0077ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.reply-actions button:hover {
    background-color: #0056b3;
}

.message-header {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.header-content strong {
    font-size: 1em;
    color: #ffffff;
    flex-grow: 1;
}

.message-actions {
    display: flex;
    gap: 5px;
    align-items: center;
}

.message-actions button {
    color: #3498db;
    font-weight: 600;
    transition: color 0.2s ease;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.message-actions button:hover {
    color: #2980b9;
    text-decoration: none;
}

.message-body {
    color: #e0e0e0;
    line-height: 1.6;
    white-space: pre-wrap;
    word-wrap: break-word;
    max-width: 100%;
    overflow-x: auto;
}

@media (max-width: 768px) {
    .email-message-box {
        max-width: 95%;
    }

    .message-header,
    .message-body {
        font-size: 0.9em;
    }
}

.founder-list,
.channel-list,
.email-threads {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.founder-item,
.channel-item,
.email-threads li {
    padding: 8px 10px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    box-sizing: border-box;
    font-size: 0.9em;
}

.founder-item.selected,
.channel-item.selected {
    background-color: #2c3e50;
    color: #ffffff;
}

.new-thread-item {
    padding: 5px 10px;
    width: 100%;
    box-sizing: border-box;
}

.new-thread-button {
    width: 100%;
    padding: 5px 10px;
    background-color: transparent;
    color: #3498db;
    border: none;
    cursor: pointer;
    text-align: left;
    font-size: 0.9em;
    transition: background-color 0.2s ease;
}

.new-thread-button:hover {
    background-color: #34495e;
}

.founder-name {
    font-size: 1em;
    font-weight: bold;
    padding: 10px;
    background-color: #3498db;
    color: white;
}

.channel-list {
    list-style-type: none;
    padding: 0;
    margin: 0 0 0 10px;
}

.channel-item {
    padding: 8px 15px;
    cursor: pointer;
    font-size: 0.9em;
    transition: all 0.2s ease;
    border-radius: 4px;
    margin-bottom: 2px;
}

.channel-item:hover {
    background-color: #34495e;
}

.channel-item.selected {
    background-color: #2c3e50;
    color: #3498db;
}

.input-area {
    background-color: #2c3e50;
    border-radius: 8px;
    padding: 12px;
    margin-top: 20px;
    flex-wrap: wrap;
    width: 100%;
    /* Ensure the input area takes full width */
    box-sizing: border-box;
    /* Include padding in the width calculation */
}

.input-area textarea {
    width: 100%;
    background-color: #34495e;
    border: none;
    color: #ecf0f1;
    min-width: 0;
    margin-right: 0;
    /* Remove right margin */
    margin-bottom: 10px;
    padding: 12px;
    border: 1px solid #2c3035;
    border-radius: 6px;
    background-color: #13161a;
    color: #ffffff;
    transition: all 0.2s ease;
    resize: vertical;
    /* Allow vertical resizing */
    min-height: 80px;
    /* Set a minimum height */
}

.input-area-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.input-area button {
    background-color: #3498db;
    transition: background-color 0.2s ease;
    padding: 12px 20px;
    background-color: #0077ff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: bold;
    white-space: nowrap;
    margin-left: 10px;
    /* Add some space between buttons */
}

.input-area button:hover {
    background-color: #2980b9;
}

.input-area button:disabled {
    background-color: #2c3035;
    cursor: not-allowed;
    opacity: 0.7;
}

.email-threads {
    margin-left: 20px;
    font-size: 0.9em;
}

.email-threads li {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
    transition: all 0.2s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.email-threads li:hover {
    background-color: #34495e;
}

.email-header {
    margin-bottom: 10px;
}

.email-header p {
    margin: 5px 0;
    font-weight: bold;
}

.email-body {
    width: 100%;
    min-height: 200px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.email-actions {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.email-actions button {
    padding: 5px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.email-actions button:hover {
    background-color: #0056b3;
}

.email-header form {
    display: flex;
}

.email-header input {
    flex: 1;
    padding: 8px;
    border: 1px solid #2c3035;
    border-radius: 4px 0 0 4px;
    background-color: #13161a;
    color: #ffffff;
}

.email-header form button {
    border-radius: 0 4px 4px 0;
}

.compose-email h4 {
    margin-bottom: 10px;
    color: #0077ff;
}

.compose-email textarea {
    width: 100%;
    min-height: 200px;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #13161a;
    color: #ffffff;
    border: 1px solid #2c3035;
    border-radius: 4px;
}

.new-thread {
    color: #3498db;
    font-style: italic;
}

.thread-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.message-preview {
    background-color: #2c3e50;
    border-radius: 15px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.2s;
    max-width: 80%;
    margin: 0 auto;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.message-preview:hover {
    background-color: #34495e;
}

.message-preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.message-preview-sender {
    font-weight: bold;
    color: #e0e0e0;
}

.message-preview-time {
    font-size: 0.8em;
    color: #bdc3c7;
}

.message-preview-body {
    color: #a0a0a0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.message-preview-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.message-preview-content strong {
    color: #3498db;
}

.message-body-preview {
    color: #666;
    margin-left: 10px;
}

.message-time {
    font-size: 0.8em;
    color: #999;
}

.collapse-button {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    font-size: 0.9em;
    padding: 5px 0;
    text-align: left;
}

.collapse-button:hover {
    text-decoration: underline;
}

.linkedin-icon {
    color: #0077B5;
    margin-left: 5px;
    vertical-align: middle;
}

.linkedin-icon:hover {
    opacity: 0.8;
}

.founder-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    width: 100%;
    box-sizing: border-box;
}

.founder-name-container {
    display: flex;
    align-items: center;
    min-width: 0;
    flex: 1;
    overflow: hidden;
}

.founder-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.founder-icons {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: 5px;
}

.email-icon,
.linkedin-icon,
.intro-request-icon,
.outreach-tag-indicator {
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    flex-shrink: 0;
}

.email-icon {
    color: #646464;
}

.linkedin-icon {
    color: #0077b5;
}

.email-icon:hover,
.linkedin-icon:hover {
    cursor: pointer;
    opacity: 0.8;
}

.new-message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
}

.chat-messages {
    position: relative;
}

.founder-icon {
    margin-left: 5px;
    cursor: pointer;
}

.email-icon,
.linkedin-icon {
    font-size: 14px;
    vertical-align: middle;
}

.email-icon {
    color: #646464;
}

.linkedin-icon {
    color: #0077b5;
}

.email-icon:hover {
    cursor: pointer;
    color: #0056b3;
}

.intro-requests {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.new-intro-request button {
    width: 100%;
    padding: 8px 10px;
    background-color: transparent;
    color: #3498db;
    border: none;
    cursor: pointer;
    text-align: left;
    font-size: 0.9em;
    transition: background-color 0.2s ease;
}

.new-intro-request button:hover {
    background-color: #34495e;
}

.founder-name-container {
    display: flex;
    align-items: center;
}

.outreach-tag-indicator {
    margin-left: 0;
    cursor: help;
}

.react-tooltip {
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
}

.react-tooltip {
    color: white !important;
    background-color: black !important;
}

.react-tooltip span {
    display: inline-block;
    margin-right: 5px;
}

.mark-in-contact-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}

.mark-in-contact-button:hover {
    background-color: #45a049;
}

.mark-in-contact-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.chat-message-container {
    max-width: 80%;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.chat-message-container>* {
    width: 100%;
}

.add-founder-item {
    padding: 10px;
}

.add-founder-button {
    width: 100%;
    padding: 8px;
    background-color: #2c3e50;
    border: 1px solid #34495e;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: #ecf0f1;
    font-size: 0.9em;
}

.add-founder-button:hover {
    background-color: #34495e;
}