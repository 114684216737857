.linkedin-chat {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
    /* Add this line */
    font-family: Arial, sans-serif;
    background-color: var(--secondary-bg);
    color: var(--text-color);
    margin: 0;
    /* Remove auto */
    /* Add this line */
}

.linkedin-chat-container {
    flex-grow: 1;
    overflow-y: auto;
    padding: 1.5rem;
    width: 100%;
    /* Add this line */
}

.message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5rem;
}

.message-icon {
    margin-right: 0.75rem;
    font-size: 1.4rem;
    color: var(--accent-color);
}

.message-content {
    border-radius: 12px;
    padding: 0.75rem 1rem;
    max-width: 70%;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1);
    background-color: var(--primary-bg);
}

.user-message .message-content {
    background-color: var(--accent-color);
    margin-left: auto;
}

.message-time {
    font-size: 0.75rem;
    color: var(--text-color);
    opacity: 0.7;
    display: block;
    margin-top: 0.5rem;
}

.linkedin-message-input {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    /* Change this to 100% */
    margin-left: 0;
    /* Remove margin */
    margin-right: 100;
    /* Remove margin */
}

.linkedin-message-input textarea {
    width: 100%;
    height: 80px;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin-bottom: 0.75rem;
    resize: none;
    font-size: 14px;
    background-color: var(--secondary-bg);
    color: var(--text-color);
    box-sizing: border-box;
    /* Add this line */
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.generate-button,
.send-button {
    padding: 0.75rem 1.25rem;
    color: var(--text-color);
    border: none;
    border-radius: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: bold;
    transition: background-color 0.2s ease;
}

.generate-button {
    background-color: var(--accent-color);
}

.generate-button:hover {
    background-color: #7a6eb8;
}

.send-button {
    background-color: var(--accent-color);
}

.send-button:hover {
    background-color: #7a6eb8;
}

.generate-button:disabled {
    background-color: rgba(98, 88, 150, 0.5);
    cursor: not-allowed;
}