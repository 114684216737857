.collapse-button {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
    background: var(--secondary-bg);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    width: 30px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    transition: background-color 0.2s ease;
    color: var(--text-color);
}

.collapse-button:hover {
    background-color: var(--accent-color);
}

.left-panel {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    background: var(--secondary-bg);
    border-right: 1px solid var(--border-color);
    padding: 20px;
    box-sizing: border-box;
    transition: transform 0.3s ease;
    z-index: 1000;
}

/* Remove all .collapsed styles as they're no longer needed */

/* ... rest of your existing CSS ... */