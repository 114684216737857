.outbound-engine {
    background-color: transparent;
    color: #ffffff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 5px;
    overflow: hidden;
    /* Prevent scrolling on the main container */
}

.table-wrapper {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.deals-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 8px;
    overflow: hidden;
}

.deals-table thead {
    position: sticky;
    top: 0;
    background-color: #1a1a2e;
    z-index: 2;
    /* Increase z-index to ensure it stays on top */
}

.table-body-container {
    flex: 1;
    overflow-y: auto;
    /* Remove the fixed height */
}

/* Remove or comment out this style if it exists */
/* .table-scroll {
    height: 100%;
    overflow-y: auto;
} */

.deals-table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}

.deals-table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}

.deals-table thead {
    position: sticky;
    top: 0;
    background-color: #1a1a2e;
    /* Adjust this color to match your design */
    z-index: 1;
}

.table-body-container {
    height: calc(100vh - 80px);
    /* Adjust height based on title and padding */
    overflow-y: auto;
}

.table-body-container .deals-table {
    margin-top: -1px;
    /* To align with the header row */
}

.deals-table th {
    padding: 15px 10px;
    text-align: left;
    border-bottom: 1px solid #2a2a4e;
}

.status-header {
    background-color: #1e1e3f;
    font-weight: bold;
    cursor: pointer;
    color: #ffffff;
    width: 100%;
    border-radius: 8px;
}

.status-header td {
    padding: 12px 10px;
    border-bottom: 1px solid #2a2a4e;
}

/* New styles for the status header cell */
.status-header td:first-child {
    width: 100%;
    /* This will make the cell take up all available space */
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.status-header td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.status-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* This will push the count to the right */
}

.status-header:hover {
    background-color: #2a2a4e;
}

.status-header svg {
    margin-right: 10px;
    color: #625896;
}

/* Styles for collapsed state */
.status-header.collapsed td {
    padding: 8px 10px;
}

/* Remove any potential gaps */
.deals-table tbody {
    display: table-row-group;
}

.deals-table tr {
    display: table-row;
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 1;
    transform: translateX(0);
    z-index: 1;
}

.deals-table td {
    display: table-cell;
    padding: 10px 5px;
}

/* Ensure full width for status headers */
.status-header td {
    padding-left: 10px;
    padding-right: 10px;
}

/* Remove any borders that might be causing gaps */
.deals-table,
.deals-table th,
.deals-table td {
    border: none;
}

/* Add a subtle border to separate rows */
.deals-table tr:not(.status-header) {
    border-bottom: 1px solid #2a2a4e;
}

/* For Webkit browsers like Chrome and Safari */
.table-container::-webkit-scrollbar {
    width: 8px;
}

.table-container::-webkit-scrollbar-track {
    background: #1a1a2e;
}

.table-container::-webkit-scrollbar-thumb {
    background-color: #625896;
    border-radius: 4px;
    border: 2px solid #1a1a2e;
}

.table-container::-webkit-scrollbar-thumb:hover {
    background-color: #7a6eb8;
}

.deals-table a {
    margin-right: 10px;
    color: #0077b5;
    text-decoration: none;
}

.deals-table a:hover {
    text-decoration: underline;
}

.deals-table td a {
    pointer-events: auto;
}

.deals-table tr {
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 1;
    transform: translateX(0);
}

.deals-table tr.disappearing {
    opacity: 0;
    transform: translateX(-100%);
}

.deals-table tr.moving-to-pipeline {
    opacity: 0;
    transform: translateX(100%);
}

.deals-table th {
    border-bottom: 1px solid #2a2a4e;
}

.deals-table tr:not(.status-header) {
    border-bottom: 1px solid #2a2a4e;
}

.deals-table tr:not(.status-header):hover {
    background-color: #2a2a4e;
}

.linkedin-icon {
    color: #0077B5;
    margin-left: 5px;
    vertical-align: middle;
}

.linkedin-icon:hover {
    opacity: 0.8;
}

.icon-link {
    margin-left: 5px;
    font-size: 14px;
    color: #007bff;
}

.icon-link:hover {
    color: #0056b3;
}

.flame-icon {
    color: #ff6b35;
    margin-left: 5px;
    cursor: help;
    font-size: 16px;
    transition: color 0.2s, transform 0.2s;
}

.flame-icon:hover {
    color: #ff8c61;
    transform: scale(1.1);
}

[data-tooltip-id] {
    cursor: help;
}

[data-tooltip-content] {
    max-width: 300px;
    word-wrap: break-word;
}

/* Updated Tooltip styles */
.react-tooltip {
    z-index: 10000000 !important;
    position: absolute !important;
    background-color: #2a2a4a !important;
    color: #fff !important;
    border-radius: 6px !important;
    padding: 12px 16px !important;
    font-size: 14px !important;
    max-width: 300px !important;
    width: auto !important;
    white-space: normal !important;
    word-wrap: break-word !important;
    opacity: 1 !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    border: 1px solid #3a3a5a !important;
    line-height: 1.4 !important;
    pointer-events: none !important;
}

.react-tooltip .tooltip-title {
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #ff6b35;
}

.react-tooltip br {
    display: block !important;
    content: "" !important;
    margin-top: 8px !important;
}

/* Ensure the flame icon is clickable and above other elements */
.icon-link {
    position: relative;
    z-index: 10001;
}

/* Improve visibility of the flame icon */
.flame-icon {
    filter: drop-shadow(0 0 2px rgba(255, 107, 53, 0.5));
    position: relative;
    z-index: 10001;
}

/* Add these styles to your existing CSS file */

.action-button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.action-button:hover {
    background-color: #4c51bf;
    /* Slightly darker on hover */
}

.action-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(90, 103, 216, 0.5);
}

.action-button-container {
    display: flex;
    gap: 10px;
}

.send-to-pipeline-button {
    background-color: #625896;
    color: white;
}

.send-to-pipeline-button:hover {
    background-color: #7a6eb8;
}

.pass-button {
    background-color: #625896;
    color: white;
}

.pass-button:hover {
    background-color: #7a6eb8;
}

.signal-received-bubble {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.85em;
    background-color: #808080;
    color: #333;
    margin-right: 5px;
    margin-bottom: 5px;
    position: relative;
    z-index: 1 !important;
    /* Lower z-index */
}

.signal-received-bubble.yellow {
    background-color: #b6a750;
    color: #333;
}

.more-statuses {
    cursor: pointer;
    background-color: #625896;
    color: white;
}

.more-statuses:hover {
    background-color: #7a6eb8;
}

/* Add this to create a stacking context for the status bubbles */
.deals-table td:nth-child(2) {
    position: relative;
}

/* Style for the status container when showing all statuses */
.deals-table td:nth-child(2):hover .signal-received-bubble {
    display: inline-block;
    z-index: 1;
}

/* Hide the "..." when showing all statuses */
.deals-table td:nth-child(2):hover .more-statuses {
    display: none;
}

.status-header {
    background-color: #1e1e3f;
    font-weight: bold;
    cursor: pointer;
    color: #ffffff;
    width: 100%;
    border-radius: 8px;
}

.status-header td {
    padding: 12px 10px;
    border-bottom: 1px solid #2a2a4e;
}

.status-header:hover {
    background-color: #2a2a4e;
}

.status-header svg {
    margin-right: 10px;
    color: #625896;
}

/* Ensure the status header spans the full width */
.status-header td {
    padding-left: 10px;
    padding-right: 10px;
}

/* Remove any margins or padding that might be causing gaps */
.deals-table tbody {
    display: table-row-group;
}

.deals-table tr {
    display: table-row;
}

.deals-table td {
    display: table-cell;
}

/* Adjust the table container if necessary */
.table-container {
    width: 100%;
    overflow-x: auto;
}

/* Remove or modify the hover effect for the chat row */
.chat-row {
    background-color: transparent !important;
}

.chat-row:hover {
    background-color: transparent !important;
}

/* Add a subtle border to separate the chat from the deal row */
.chat-row td {
    border-top: 1px solid #2a2a4e;
    padding: 5px 0;
    /* Reduced padding */
}

/* Ensure the OmniChat component doesn't inherit hover styles */
.chat-row .omni-chat {
    background-color: transparent;
    margin: 0;
    padding: 0;
}

.signal-received-bubble.red {
    background-color: #ff4d4d;
    color: white;
}

.signal-received-bubble.green {
    background-color: #4CAF50;
    color: white;
}

.outbound-engine-title {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    margin: 5px 0 10px;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 2px;
    flex-shrink: 0;
    /* Remove any background color */
    background-color: transparent;
}

.title-text {
    /* Remove the gradient background */
    background: none;
    -webkit-background-clip: initial;
    background-clip: initial;
    -webkit-text-fill-color: initial;
    display: inline-block;
    /* The text color is preserved from the parent element */
}

.title-underline {
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 2px;
    background: linear-gradient(90deg, #625896, #ffffff);
    border-radius: 1.5px;
}