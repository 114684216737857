.file-panel-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    /* Increased width */
    height: 100%;
    background-color: #0f0d2e;
    border-left: 1px solid #625896;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow-y: auto;
    padding: 20px;
}

.file-panel-container h2 {
    color: white;
    margin-bottom: 20px;
    text-align: Left;
    /* Centered heading */
}

.file-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.file-input-button {
    background-color: #6c63ff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s, box-shadow 0.3s;
    margin-top: 10px;
}

.file-input-button:hover {
    background-color: #5a52e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.file-input-button:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
}

.file-input {
    display: none;
}

.file-input-label {
    background-color: #6c63ff;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    margin-bottom: 10px;
}

.file-input-label:hover {
    background-color: #5a52e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.file-name {
    color: white;
    margin-bottom: 10px;
    word-break: break-all;
    text-align: center;
    /* Centered file name */
}

.file-panel {
    position: relative;
    width: 100%;
    /* Ensure the panel takes full width */
}

.file-panel li {
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    color: white;
    padding: 10px;
    border: 1px solid #625896;
    /* Added border */
    border-radius: 4px;
    /* Rounded corners */
    background-color: #1a1745;
    /* Background color for items */
    /* Fixed width */
    position: relative;
    left: 36.5%;
    transform: translateX(-50%);
    /* Center the box horizontally */
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    text-overflow: clip;
}

.file-panel li div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.file-panel button {
    background-color: #625896;
    border: none;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
    margin-left: 5px;
    /* Added margin */
}

.file-panel button:hover {
    background-color: #1a1745;
}

.file-panel-container button.close-button {
    background-color: #6c63ff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s, box-shadow 0.3s;
    margin-top: 20px;
    display: block;
    width: 100%;
    text-align: center;
}

.file-panel-container button.close-button:hover {
    background-color: #5a52e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}