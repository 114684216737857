body,
.App {
  background-color: #0f0d2e;
  color: #e0e0e0;
}

html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  /* Prevent scrolling on the body */
}

.app-container {
  display: flex;
  height: 100vh;
  /* Full height of the viewport */
  overflow: hidden;
  /* Prevent scrolling on the app container */
  transition: padding-left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  padding-left: 250px;
  /* Adjust this value to match your left panel width */
}

.app-container.full-width {
  padding-left: 0;
}

.table-container {
  overflow-x: auto;
  max-height: 80vh;
  border: 1px solid #625896;
  border-radius: 10px;
  position: relative;
}

.table-scroll {
  min-width: 100%;
  overflow-x: auto;
}

.table-container table {
  width: auto;
  min-width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
}

.table-container th,
.table-container td {
  padding: 8px;
  border: 1px solid #625896;
  color: #e0e0e0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 150px;
}

.table-container th {
  background-color: #1a1745;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom: 2px solid #625896;
  color: #ffffff;
  padding: 12px 8px;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.table-container thead {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table-container tr:nth-child(even) {
  background-color: rgba(0, 123, 255, 0.1);
}

.table-container tr:hover {
  background-color: rgba(0, 123, 255, 0.2);
}

input[type="text"],
select,
button {
  background-color: #0f0d2e;
  color: #e0e0e0;
  border: 1px solid #625896;
  border-radius: 5px;
  padding: 8px 12px;
}

button {
  background-color: #007bff;
  color: #e0e0e0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

.deals-container {
  padding: 20px;
  transition: width 0.3s ease;
}

.deals-container.panel-open {
  width: 60%;
}

.deals-layout {
  display: flex;
  gap: 20px;
}

.deals-options {
  width: 250px;
  flex-shrink: 0;
}

.deals-table {
  flex-grow: 1;
}

.search-container {
  margin-bottom: 20px;
}

select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.table-container {
  overflow-y: auto;
  /* Allow scrolling within the table container */
  max-height: 80vh;
  border: 1px solid #625896;
  border-radius: 10px;
  position: relative;
}

.table-container table {
  width: 100%;
  table-layout: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.table-container th,
.table-container td {
  padding: 8px;
  border: 1px solid #625896;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-container th {
  background-color: #1a1745;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom: 2px solid #625896;
  color: #ffffff;
  padding: 12px 8px;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.table-container thead {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table-container .group-header {
  background-color: #1a1745;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.table-container .group-header td {
  padding: 12px 15px;
  color: #a0a0a0;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.table-container .group-header:hover {
  background-color: #231f5a;
}

.table-container .group-header .group-indicator {
  display: inline-block;
  width: 20px;
  text-align: center;
  margin-right: 10px;
  color: #625896;
}

.table-container .group-header .group-name {
  background-color: #007bff;
  color: #ffffff;
  padding: 4px 10px;
  border-radius: 15px;
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

.table-container .group-header .group-count {
  color: #625896;
  font-weight: normal;
}

.table-container .stage-label {
  background-color: #e0f7fa;
  padding: 2px 6px;
  border-radius: 4px;
}

.table-container .lead-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  font-weight: bold;
  color: white;
  background-color: #2196F3;
}

table {
  width: auto;
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f2f2f2;
}

th,
td {
  padding: 8px;
  border: 1px solid #625896;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th {
  position: sticky;
  top: 0;
  background-color: #0f0d2e;
  z-index: 2;
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #625896;
  white-space: nowrap;
}

th,
td {
  border-right: 1px solid #625896;
}

th:last-child,
td:last-child {
  border-right: none;
}

.th-content {
  position: relative;
  display: flex;
  align-items: center;
}

.column-resizer {
  position: absolute;
  right: -5px;
  top: 0;
  height: 100%;
  width: 10px;
  background-color: #625896;
  cursor: col-resize;
  z-index: 1;
}

.column-resizer:hover,
.resizing .column-resizer {
  background-color: #0056b3;
}

.resizing {
  cursor: col-resize;
  user-select: none;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-top: 20px;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  color: #007bff;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #625896;
  border-radius: 5px;
}

.page-item.active .page-link {
  background-color: #007bff;
  color: #e0e0e0;
  border-color: #007bff;
}

.table-header {
  background-color: #f2f2f2;
  font-weight: bold;
}

.table-group-header {
  background-color: #f9f9f9;
  padding: 8px;
  font-weight: bold;
}

.nav-tabs {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  background-color: #0f0d2e;
  margin-bottom: 20px;
}

.nav-tabs li {
  display: inline;
}

.nav-tabs a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #e0e0e0;
}

.nav-tabs a:hover {
  background-color: #007bff;
}

.nav-tabs a.active {
  background-color: #0056b3;
}

input[type="text"] {
  width: 100%;
  box-sizing: border-box;
}

.table-cell {
  padding: 8px;
  border: 1px solid #625896;
  text-align: left;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-cell.checkbox-column {
  width: 35px;
  min-width: 35px;
  max-width: 35px;
  padding: 0;
  text-align: center;
}

.table-cell.checkbox-column input[type="checkbox"] {
  margin: 0;
  padding: 0;
  width: 14px;
  height: 14px;
}

.hovered-row {
  background-color: rgba(0, 123, 255, 0.2);
}

.selected-cell {
  outline: 2px solid #007bff;
  outline-offset: -2px;
}

input[type="text"] {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #625896;
  outline: none;
}

.group-header {
  background-color: #0f0d2e;
  font-weight: bold;
  cursor: pointer;
}

.group-header td {
  padding: 10px;
}

.group-header:hover {
  background-color: rgba(0, 123, 255, 0.2);
}

.search-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #625896;
  border-radius: 4px;
  background-color: #0f0d2e;
  color: #e0e0e0;
}

.search-results {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.highlight {
  background-color: #007bff;
  color: #e0e0e0;
  font-weight: bold;
}

th span {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.note-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: #0f0d2e;
  box-shadow: -2px 0 5px rgba(0, 123, 255, 0.3);
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}

.note-item {
  position: relative;
  padding: 10px;
  border: 1px solid #625896;
  margin-bottom: 10px;
}

.delete-note {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
}

.note-item:hover .delete-note {
  opacity: 1;
}

.note-item p {
  min-height: 20px;
  padding: 5px;
  border: 1px solid transparent;
}

.note-item p:hover {
  cursor: pointer;
  background-color: rgba(0, 123, 255, 0.2);
  border: 1px dashed #007bff;
}

.note-item textarea {
  width: 100%;
  min-height: 60px;
  padding: 5px;
  border: 1px solid #625896;
  border-radius: 4px;
  background-color: #0f0d2e;
  color: #e0e0e0;
}

.table-cell input {
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  border: none;
  background-color: #0f0d2e;
  color: #e0e0e0;
}

.table-cell input:focus {
  outline: 2px solid #007bff;
}

.app-container {
  display: flex;
  height: 100vh;
}

.left-panel {
  width: 200px;
  min-width: 200px;
  background-color: #0f0d2e;
  padding: 20px;
  height: 100vh;
  border-right: 1px solid #625896;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  /* Prevent scrolling on the left panel */
  position: relative;
  /* Ensure the sign-out button is positioned correctly */
}

.logo-container {
  margin-bottom: 0px;
  padding: 10% 0;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #625896;
}

.logo {
  width: 80%;
  max-width: 150px;
  height: auto;
  display: block;
}

.panel-header {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #e0e0e0;
}

.left-panel nav {
  flex-grow: 1;
  margin-top: 10px;
  overflow-y: auto;
  /* Allow scrolling within the nav section if needed */
}

.left-panel nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.left-panel nav ul li {
  margin-bottom: 5px;
}

.left-panel nav ul li a {
  text-decoration: none;
  color: #e0e0e0;
  display: block;
  padding: 8px 10px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.left-panel nav ul li.active a {
  background-color: #007bff;
  font-weight: bold;
}

.left-panel nav ul li a:hover {
  background-color: rgba(0, 123, 255, 0.2);
}

.sign-out-button {
  margin-top: 10px;
  width: 85% !important;
  /* Hardcoded width */
  padding: 10px;
  box-sizing: border-box;
  position: absolute;
  bottom: 60px !important;
  left: 0 !important;
  right: 0 !important;
  margin-left: auto;
  margin-right: auto;
  /* Adjust the left spacing as needed */
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  /* Allow scrolling within the main content */
  width: calc(100% - 200px);
  transition: margin-left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.main-content.full-width {
  margin-left: 0;
}

.table-container select {
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #625896;
}

.table-container select option {
  padding: 5px;
}

.stage-select {
  appearance: none;
  padding: 5px 10px;
  border-radius: 15px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  min-width: 120px;
  transition: background-color 0.3s ease;
}

.stage-select option {
  background-color: white;
  color: black;
}

.deal-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background-color: #0f0d2e;
  box-shadow: -2px 0 5px rgba(0, 123, 255, 0.3);
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}

.deal-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.description-cell {
  width: 600px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.deal-panel-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.deal-info,
.company-info,
.founders-info {
  background-color: #1a1a2e;
  padding: 15px;
  border-radius: 5px;
}

.founder-info {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #1a1a2e;
  border-radius: 5px;
}

.deal-panel h2 {
  margin-top: 0;
}

.deal-panel button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.new-deal-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0f0d2e;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.3);
  z-index: 1000;
}

.new-deal-form form {
  display: flex;
  flex-direction: column;
}

.new-deal-form input,
.new-deal-form select,
.new-deal-form button {
  margin-bottom: 10px;
  padding: 5px;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
}

.tab {
  padding: 10px 15px;
  background-color: #1a1a2e;
  border: none;
  margin-right: 5px;
  cursor: pointer;
  color: #e0e0e0;
}

.tab.active {
  background-color: #007bff;
}

.actions {
  display: flex;
  align-items: center;
}

.action-button {
  margin-right: 10px;
  padding: 5px 10px;
}

.table-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.table-actions select,
.table-actions button {
  margin-right: 10px;
}

.pagination-info {
  font-size: 14px;
}

.column-selector {
  margin-bottom: 20px;
}

.column-selector label {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
}

.column-selector input[type="checkbox"] {
  margin-right: 5px;
}

.inactive-header th {
  color: #999 !important;
  background-color: #1a1a2e !important;
}

.pagination-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.pagination-controls span:first-child {
  white-space: nowrap;
}

.pagination-controls select {
  padding: 2px 5px;
  border: 1px solid #625896;
  border-radius: 4px;
  font-size: 14px;
  width: 60px;
  background-color: #0f0d2e;
  color: #e0e0e0;
}

.pagination-controls button {
  padding: 2px 8px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: #e0e0e0;
}

.pagination-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Add these styles to your App.css file */

.select-dropdown {
  background-color: var(--primary-bg);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 2px 5px;
}

.select-dropdown option {
  background-color: var(--primary-bg);
  color: var(--text-color);
}

.select-dropdown option[selected] {
  background-color: var(--accent-color);
  color: var(--text-color);
}

.table-scroll {
  overflow-x: auto;
  width: 100%;
}

.table-container .column-id {
  min-width: 60px;
  max-width: 100px;
}

.table-container .column-name {
  min-width: 200px;
}

.founder-panel-container {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  height: 100%;
  background-color: #0f0d2e;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
}

.founder-panel h2 {
  color: white;
  margin-bottom: 20px;
}

.founder-item {
  margin-bottom: 20px;
}

.founder-content p {
  color: white;
  margin: 5px 0;
}

.react-select-container {
  font-family: Arial, sans-serif;
}

.react-select__control {
  background-color: #1a1433;
  border: 1px solid #625896;
  border-radius: 8px;
  box-shadow: none;
  color: white;
  min-height: 45px;
}

.react-select__control:hover {
  border-color: #625896;
}

.react-select__control--is-focused {
  border-color: #625896;
  box-shadow: none;
}

.react-select__menu {
  background-color: #1a1433;
  border: 1px solid #625896;
  border-radius: 8px;
  color: white;
  z-index: 9999 !important;
}

.react-select__option {
  background-color: #1a1433;
  color: white;
  padding: 10px;
}

.edit-columns-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(8, 1, 37, 0.7);
  z-index: 100001;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.react-select__option--is-focused {
  background-color: #625896;
  color: white;
}

.react-select__option--is-selected {
  background-color: #625896;
  color: white;
}

.react-select__single-value,
.react-select__input-container,
.react-select__input {
  color: white;
}

.greyed-out-header {
  color: grey;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 0;
}

.react-select__placeholder,
.react-select__indicator {
  color: #a0a0a0;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__multi-value {
  background-color: #625896;
  border-radius: 4px;
}

.react-select__multi-value__label {
  color: white;
}

.react-select__multi-value__remove {
  color: white;
}

.react-select__multi-value__remove:hover {
  background-color: #7a6eb8;
  color: white;
}

.founder-panel .react-select__menu {
  background-color: #1a1433;
  border: 1px solid #625896;
  border-radius: 8px;
  color: white;
}

.founder-panel .react-select__option {
  background-color: #1a1433;
  color: white;
  padding: 10px;
}

.founder-panel .react-select__option--is-focused {
  background-color: #625896;
  color: white;
}

.founder-panel .react-select__option--is-selected {
  background-color: #625896;
  color: white;
}

.founder-panel .react-select__single-value,
.founder-panel .react-select__input-container,
.founder-panel .react-select__input {
  color: white;
}

.founder-panel .react-select__placeholder,
.founder-panel .react-select__indicator {
  color: #a0a0a0;
}

.founder-panel .react-select__indicator-separator {
  display: none;
}

.founder-panel .react-select__multi-value {
  background-color: #625896;
  border-radius: 4px;
}

.founder-panel .react-select__multi-value__label {
  color: white;
}

.founder-panel .react-select__multi-value__remove {
  color: white;
}

.founder-panel .react-select__multi-value__remove:hover {
  background-color: #7a6eb8;
  color: white;
}

html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  /* Prevent scrolling on the body */
}

.app-container {
  display: flex;
  height: 100vh;
  /* Full height of the viewport */
  overflow: hidden;
  /* Prevent scrolling on the app container */
  transition: padding-left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  padding-left: 250px;
  /* Adjust this value to match your left panel width */
}

.app-container.full-width {
  padding-left: 0;
}

.left-panel {
  width: 200px;
  min-width: 200px;
  background-color: #0f0d2e;
  padding: 20px;
  height: 100vh;
  border-right: 1px solid #625896;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  /* Prevent scrolling on the left panel */
  position: relative;
  /* Ensure the sign-out button is positioned correctly */
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 250px;
  /* Adjust as needed */
  z-index: 1000;
}

.left-panel nav {
  flex-grow: 1;
  margin-top: 10px;
  overflow-y: auto;
  /* Allow scrolling within the nav section if needed */
}

.sign-out-button {
  margin-top: 10px;
  width: 100%;
  /* Hardcoded width */
  padding: 10px;
  box-sizing: border-box;
  position: absolute;
  bottom: 60px !important;
  left: 0 !important;
  right: 0 !important;
  margin-left: auto;
  margin-right: auto;
  /* Adjust the left spacing as needed */
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  /* Allow scrolling within the main content */
  width: calc(100% - 200px);
  transition: margin-left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.main-content.full-width {
  margin-left: 0;
}

.table-container {
  overflow-y: auto;
  /* Allow scrolling within the table container */
  max-height: 80vh;
  border: 1px solid #625896;
  border-radius: 10px;
  position: relative;
}

.react-select-container {
  color: black;
}

.react-select__control {
  background-color: #0f0d2e !important;
  border-color: #625896 !important;
}

.react-select__menu {
  background-color: #0f0d2e !important;
}

.react-select__option {
  background-color: #0f0d2e !important;
  color: white !important;
}

.react-select__option--is-focused {
  background-color: #1a1745 !important;
}

.react-select__single-value {
  color: white !important;
}

.react-select__multi-value {
  background-color: #625896 !important;
}

.react-select__multi-value__label {
  color: white !important;
}