.deal-page {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: var(--text-color);
    /* background-color: var(--primary-bg); */
}

.deal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.deal-title {
    font-size: 24px;
    font-weight: bold;
    margin-right: 20px;
}

.deal-stage {
    flex-shrink: 0;
}

.stage-dropdown {
    width: 200px;
    /* Fixed width */
    padding: 8px;
    font-size: 16px;
    border: 1px solid #625896;
    border-radius: 4px;
    background-color: #0f0d2e;
    color: #e0e0e0;
    cursor: pointer;
}

.stage-dropdown:focus {
    outline: none;
    border-color: #8a7ac5;
}

.stage-dropdown option {
    background-color: #0f0d2e;
    color: #e0e0e0;
}

.deal-description {
    font-size: 16px;
    color: #a6a6a6;
    cursor: pointer;
}

.deal-toolbar {
    display: flex;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 20px;
}

.deal-toolbar button {
    background: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    color: #555;
    border-bottom: 2px solid transparent;
    transition: color 0.3s, border-bottom 0.3s;
    margin-right: 10px;
}

.deal-toolbar button:hover {
    color: var(--text-color);
}

.deal-toolbar button.active {
    color: var(--text-color);
    border-bottom: 2px solid var(--text-color);
}

.tab-content {
    padding: 20px;
    background: var(--primary-bg);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    margin-top: 20px;
}

.deal-page-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.deal-page-footer button {
    padding: 10px 20px;
    border: none;
    background-color: var(--accent-color);
    color: var(--text-color);
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s;
}

.deal-page-footer button:hover {
    background-color: #7a6eb8;
}

.people-cards,
.founders-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.person-card,
.founder-card {
    background-color: #2c2c2c;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.person-card h4,
.founder-card h4 {
    margin: 0 0 10px 0;
}

.person-card p,
.founder-card p {
    margin: 5px 0;
}

.person-card ul,
.founder-card ul {
    padding-left: 20px;
}

.file-input-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.file-list {
    list-style: none;
    padding: 0;
}

.file-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #2c2c2c;
    margin-bottom: 10px;
    border-radius: 4px;
}

.file-list li div {
    display: flex;
    gap: 10px;
}

.file-input-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.file-list {
    list-style: none;
    padding: 0;
}

.file-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #2c2c2c;
    margin-bottom: 10px;
    border-radius: 4px;
}

.file-list li div {
    display: flex;
    gap: 10px;
}

.memo-section {
    margin-top: 20px;
}

.memo-textarea {
    width: 100%;
    min-height: 200px;
    padding: 10px;
    font-family: inherit;
    font-size: inherit;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.memo-content {
    min-height: 100px;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 4px;
    cursor: pointer;
}

.memo-content:hover {
    background-color: #f9f9f9;
}

.react-select__control {
    background-color: #0f0d2e;
    border-color: #625896;
    color: #e0e0e0;
}

.react-select__single-value {
    color: #e0e0e0;
}

.react-select__control--is-focused {
    border-color: #7a6eb8;
    box-shadow: 0 0 0 1px #7a6eb8;
}

.react-select__value-container {
    color: #e0e0e0;
}

.react-select__input-container {
    color: #e0e0e0;
}

.react-select__input {
    color: #e0e0e0 !important;
}

.react-select__menu {
    background-color: #0f0d2e;
    border: 1px solid #625896;
}

.react-select__option {
    background-color: #0f0d2e;
    color: #e0e0e0;
}

.react-select__option--is-focused {
    background-color: #1a1433;
}

.react-select__option--is-selected {
    background-color: #625896;
}

.react-select__placeholder {
    color: #a0a0a0;
}

.add-people-button {
    background-color: #625896;
    color: #e0e0e0;
    border: none;
    padding: 8px 16px;
    margin-top: 8px;
    cursor: pointer;
    border-radius: 4px;
}

.add-people-button:hover {
    background-color: #7a6eb8;
}

.react-select__indicator-separator {
    background-color: #625896;
}

.react-select__indicator {
    color: #625896;
}

.react-select__multi-value {
    background-color: #625896;
}

.react-select__multi-value__label {
    color: #e0e0e0;
}

.react-select__multi-value__remove {
    color: #e0e0e0;
}

.react-select__multi-value__remove:hover {
    background-color: #7a6eb8;
    color: #e0e0e0;
}

button {
    background-color: #625896;
    color: #e0e0e0;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #7a6eb8;
}