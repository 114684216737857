.new-deal-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(8, 1, 37, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1002;
}

.new-deal-modal {
    background-color: #0f0d2e;
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 90%;
    padding: 30px;
    font-family: Arial, sans-serif;
    position: relative;
    z-index: 1003;
    color: white;
}

.new-deal-modal h2 {
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 24px;
    text-align: center;
    color: white;
}

.new-deal-form {
    display: flex;
    flex-direction: column;
}

.new-deal-form .input-group {
    position: relative;
    margin-bottom: 20px;
}

.new-deal-form label {
    display: block;
    margin-bottom: 5px;
    color: #a0a0a0;
}

.new-deal-form input,
.new-deal-form select {
    width: 100%;
    padding: 12px;
    border: 1px solid #625896;
    border-radius: 8px;
    font-size: 14px;
    transition: border-color 0.3s, box-shadow 0.3s;
    box-sizing: border-box;
    background-color: #1a1433;
    color: white;
}

.new-deal-form input:focus,
.new-deal-form select:focus {
    outline: none;
    border-color: #625896;
    box-shadow: 0 0 0 2px rgba(98, 88, 150, 0.2);
}

.new-deal-form .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
}

.new-deal-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.1s;
}

.new-deal-form .cancel-btn {
    background-color: #080125;
    color: white;
    margin-right: 15px;
}

.new-deal-form .cancel-btn:hover {
    background-color: #7a6eb8;
}

.new-deal-form .create-btn {
    background-color: #625896;
    color: white;
}

.new-deal-form .create-btn:hover {
    background-color: #1a1433;
    transform: translateY(-2px);
}