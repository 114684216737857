.column-selector-modal {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    background-color: #0f0d2e !important;
    border: 1px solid #625896 !important;
    border-radius: 8px !important;
    padding: 20px !important;
    z-index: 1002 !important;
    /* Increased z-index */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5) !important;
    pointer-events: auto !important;
}

.edit-columns-modal-overlay {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, 0) !important;
    /* Adjusted opacity */
    z-index: 1000 !important;
    pointer-events: none !important;
    /* Ensure this is set */
}

.column-selector-modal h2 {
    margin-top: 0;
    margin-bottom: 20px;
}

.search-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    background-color: #1a1433;
    border: 1px solid #625896;
    color: white;
    border-radius: 4px;
}

.columns-container {
    display: flex;
    justify-content: space-between;
}

.all-columns,
.visible-columns {
    width: 45%;
    height: 300px;
    overflow-y: auto;
    border: 1px solid #625896;
    padding: 10px;
    background-color: #1a1433;
}

.all-columns h3,
.visible-columns h3 {
    margin-top: 0;
    margin-bottom: 10px;
}

.column-item {
    display: block;
    margin-bottom: 5px;
}

.selected-column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2a2447;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
}

.column-controls {
    display: flex;
}

.column-controls button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #625896;
    margin-left: 5px;
}

.column-controls button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.action-buttons button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.action-buttons button:not(.apply-button) {
    background-color: #080125;
    color: white;
}

.action-buttons .apply-button {
    background-color: #007bff;
    color: white;
    order: 2;
}

.action-buttons .cancel-button {
    order: 1;
}

.action-buttons .clear-button {
    margin-right: auto;
    order: 0;
}