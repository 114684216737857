.add-company-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(8, 1, 37, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1002;
}

.add-company-modal {
    background-color: var(--secondary-bg);
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 90%;
    padding: 30px;
    font-family: Arial, sans-serif;
    position: relative;
    z-index: 1003;
    color: var(--text-color);
}

.add-company-modal h2 {
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 24px;
    text-align: center;
    color: white;
    /* Updated: white text */
}

.add-company-modal .input-group {
    position: relative;
    margin-bottom: 20px;
}

.add-company-modal .input-icon {
    position: absolute;
    left: 12px;
    top: 13px;
    color: #625896;
    /* Updated: purple icon color */
    font-size: 18px;
}

.add-company-modal input,
.add-company-modal textarea,
.add-company-modal .basic-multi-select .select__control {
    width: 100%;
    padding: 12px 12px 12px 40px;
    border: 1px solid #625896;
    /* Updated: purple border */
    border-radius: 8px;
    font-size: 14px;
    transition: border-color 0.3s, box-shadow 0.3s;
    box-sizing: border-box;
    background-color: #1a1433;
    /* Added: slightly lighter background */
    color: white;
    /* Added: white text */
}

.add-company-modal textarea {
    height: 100px;
    resize: vertical;
    min-height: 45px;
}

.add-company-modal .basic-multi-select .select__control {
    padding-left: 40px;
    height: auto;
    min-height: 45px;
}

.add-company-modal input:focus,
.add-company-modal textarea:focus,
.add-company-modal .basic-multi-select .select__control--is-focused {
    outline: none;
    border-color: #625896;
    /* Updated: purple border on focus */
    box-shadow: 0 0 0 2px rgba(98, 88, 150, 0.2);
    /* Updated: purple shadow */
}

.add-company-modal .modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
}

.add-company-modal button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.1s;
}

.add-company-modal .cancel-btn {
    background-color: #080125;
    /* Updated: purple background */
    color: white;
    /* Updated: white text */
    margin-right: 15px;
}

.add-company-modal .cancel-btn:hover {
    background-color: #7a6eb8;
    /* Updated: lighter purple on hover */
}

.add-company-modal .add-btn {
    background-color: #625896;
    /* Updated: dark blue background */
    color: white;
    /* Kept: white text */
}

.add-company-modal .add-btn:hover {
    background-color: #1a1433;
    /* Updated: slightly lighter blue on hover */
    transform: translateY(-2px);
}

/* Adjust icon positioning for textarea */
.add-company-modal .input-group:has(textarea) .input-icon {
    top: 14px;
}

/* Styles for react-select */
.add-company-modal .select__control {
    background-color: #1a1433;
    border: 1px solid #625896;
    border-radius: 8px;
    box-shadow: none;
    color: white;
    min-height: 45px;
}

.add-company-modal .select__control:hover {
    border-color: #625896;
}

.add-company-modal .select__control--is-focused {
    border-color: #625896;
    box-shadow: none;
}

.add-company-modal .select__menu {
    background-color: #1a1433;
    border: 1px solid #625896;
    border-radius: 8px;
    color: white;
}

.add-company-modal .select__option {
    background-color: #1a1433;
    color: white;
}

.add-company-modal .select__option--is-focused,
.add-company-modal .select__option--is-selected {
    background-color: #625896;
}

.add-company-modal .select__single-value,
.add-company-modal .select__input-container,
.add-company-modal .select__input {
    color: white;
}

.add-company-modal .select__placeholder,
.add-company-modal .select__indicator {
    color: #a0a0a0;
}

.add-company-modal .select__indicator-separator {
    display: none;
}

.add-company-modal .select__multi-value {
    background-color: #625896;
    border-radius: 4px;
}

.add-company-modal .select__multi-value__label {
    color: white;
}

.add-company-modal .select__multi-value__remove {
    color: white;
}

.add-company-modal .select__multi-value__remove:hover {
    background-color: #7a6eb8;
    color: white;
}

.create-deal-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-left: 12px;
    gap: 8px;
}

.create-deal-icon {
    display: none;
}

.create-deal-group {
    display: flex;
    align-items: center;
    gap: 8px;
    color: white;
    justify-content: flex-start;
}

.create-deal-checkbox {
    margin: 0;
    width: 16px !important;
    /* Fixed width */
    height: 16px !important;
    /* Fixed height */
    cursor: pointer;
    background-color: #1a1433;
    color: white;
    border: 1px solid #625896;
    border-radius: 4px !important;
    /* Make it slightly rounded */
    appearance: none;
    /* Remove default checkbox styling */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, border-color 0.3s;
    padding: 0;
    /* Ensure no padding */
    box-sizing: border-box;
    /* Include border in the element's total width and height */
}

.create-deal-checkbox:checked {
    background-color: #625896;
    color: white;
}

.create-deal-checkbox:checked::before {
    content: '✔';
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    /* Ensure the checkmark is centered */
}

.create-deal-label {
    margin-left: 8px;
    cursor: pointer;
    color: #a0a0a0;
    font-size: 14px;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}