.add-person-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(8, 1, 37, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1002;
    /* Ensure this is higher than other elements */
}

.add-person-modal {
    background-color: #0f0d2e;
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 90%;
    padding: 30px;
    font-family: Arial, sans-serif;
    position: relative;
    z-index: 40003;
    /* Ensure this is higher than the overlay */
    color: white;
}

.add-person-modal h2 {
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 24px;
    text-align: center;
    color: white;
}

.add-person-modal .input-group {
    position: relative;
    margin-bottom: 20px;
}

.add-person-modal .input-icon {
    position: absolute;
    left: 12px;
    top: 13px;
    color: #625896;
    font-size: 18px;
}

.add-person-modal input,
.add-person-modal .react-select-container .react-select__control {
    width: 100%;
    padding: 12px 12px 12px 40px;
    border: 1px solid #625896;
    border-radius: 8px;
    font-size: 14px;
    transition: border-color 0.3s, box-shadow 0.3s;
    box-sizing: border-box;
    background-color: #1a1433;
    color: white;
}

.add-person-modal input:focus,
.add-person-modal .react-select-container .react-select__control--is-focused {
    outline: none;
    border-color: #625896;
    box-shadow: 0 0 0 2px rgba(98, 88, 150, 0.2);
}

.add-person-modal .modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
}

.add-person-modal button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.1s;
}

.add-person-modal .cancel-btn {
    background-color: #080125;
    color: white;
    margin-right: 15px;
}

.add-person-modal .cancel-btn:hover {
    background-color: #7a6eb8;
}

.add-person-modal .add-btn {
    background-color: #625896;
    color: white;
}

.add-person-modal .add-btn:hover {
    background-color: #1a1433;
    transform: translateY(-2px);
}

.add-person-modal .react-select__menu {
    background-color: #1a1433;
    border: 1px solid #625896;
    border-radius: 8px;
    color: white;
}

.add-person-modal .react-select__option {
    background-color: #1a1433;
    color: white;
}

.add-person-modal .react-select__option--is-focused,
.add-person-modal .react-select__option--is-selected {
    background-color: #625896;
}

.add-person-modal .react-select__single-value,
.add-person-modal .react-select__input-container,
.add-person-modal .react-select__input {
    color: white;
}

.add-person-modal .react-select__placeholder,
.add-person-modal .react-select__indicator {
    color: #a0a0a0;
}

.add-person-modal .react-select__indicator-separator {
    display: none;
}

.error-message {
    color: #ff6b6b;
    margin-top: 10px;
    font-size: 14px;
}

h2 {
    margin-bottom: 20px;
    font-size: 24px;
}