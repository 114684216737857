.login-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    overflow: hidden;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.hero-back {
    background-image: url('./assets/images/hero-back.webp');
    z-index: 1;
}

.hero-front {
    background-image: url('./assets/images/hero-front.webp');
    z-index: 2;
}

.login-content {
    width: 100%;
    padding: 2rem;
    margin-bottom: 5vh;
    text-align: center;
    position: relative;
    z-index: 3;
}

.login-button {
    padding: 12px 24px;
    font-size: 0.9rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.login-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}